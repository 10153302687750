<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  methods: {
    loadFont() {
      const filename = `${window.location.origin}/font/css/fontstyle_${this.$store.state.lang}.css`;

      var style = document.createElement("style");
      style.type = "text/css";
      style.appendChild(document.createTextNode(`@import url(${filename});`));
      document.getElementsByTagName("head")[0].appendChild(style);
    },
  },
  mounted() {
    this.loadFont();
  },
};
</script>

<style>
</style>