<template>
  <div class="logindiv">
    <div
      style="
        position: absolute;
        top: -23%;
        left: 2%;
        color: white;
        font-family: notosans;
      "
    >
      <p style="font-size: 30px">
        {{ langlist[$store.state.lang]["회원 정보 입력"] }}
      </p>
      <p style="font-size: 55px; margin-top: -20px">
        {{ langlist[$store.state.lang]["나만 아는 비밀번호를 입력해주세요."] }}
      </p>
    </div>
    <div>
      <input
        v-show="iseyeopen == false"
        v-model="pw"
        type="password"
        :placeholder="langlist[$store.state.lang]['새 비밀번호']"
        class="pwinput1"
      />
      <input
        v-show="iseyeopen == true"
        type="text"
        v-model="pw"
        :placeholder="langlist[$store.state.lang]['새 비밀번호']"
        class="pwinput1"
      />
      <img
        @click="eyeopen()"
        v-show="iseyeopen == false"
        style="
          width: 55px;
          float: right;
          margin-top: 90px;
          position: absolute;
          margin-left: 910px;
          cursor: pointer;
        "
        src="../assets/eyeclose.png"
        alt=""
      />
      <img
        @click="eyeopen()"
        v-show="iseyeopen == true"
        style="
          width: 55px;
          float: right;
          margin-top: 90px;
          position: absolute;
          margin-left: 910px;
          cursor: pointer;
        "
        src="../assets/eyeopen.png"
        alt=""
      />
    </div>
    <div>
      <input
        v-show="iseyeopen2 == false"
        v-model="pwcheck"
        type="password"
        :placeholder="langlist[$store.state.lang]['비밀번호 확인']"
        class="pwinput2"
      />
      <input
        v-show="iseyeopen2 == true"
        type="text"
        v-model="pwcheck"
        placeholder="Password"
        class="pwinput2"
      />
      <img
        @click="eyeopen2()"
        v-show="iseyeopen2 == false"
        style="
          width: 55px;
          float: right;
          margin-top: 200px;
          position: absolute;
          margin-left: 910px;
          cursor: pointer;
        "
        src="../assets/eyeclose.png"
        alt=""
      />
      <img
        @click="eyeopen2()"
        v-show="iseyeopen2 == true"
        style="
          width: 55px;
          float: right;
          margin-top: 200px;
          position: absolute;
          margin-left: 910px;
          cursor: pointer;
        "
        src="../assets/eyeopen.png"
        alt=""
      />
    </div>
    <p
      style="
        position: absolute;
        bottom: 47%;
        left: 10%;
        font-family: notosans;
        color: #314c99;
      "
    >
      *
      {{
        langlist[$store.state.lang][
          "비밀번호는 영문 대소문자/숫자 조합 6자 이상 가능합니다."
        ]
      }}
    </p>
    <div @click="pwok()" class="pwokbtn"></div>
  </div>
</template>

<script>
import { userpasswordchange } from "../api.js";
import { ko, vt } from "@/_translate/translate.js";

export default {
  created() {
    window.addEventListener("loginChallenge", this.loginChallenge);
  },
  beforeDestroy() {
    window.removeEventListener("loginChallenge", this.loginChallenge);
  },
  data() {
    return {
      pw: "",
      pwcheck: "",
      iseyeopen: false,
      iseyeopen2: false,
      token: "",
      langlist: {
        ko,
        vt,
      },
    };
  },
  methods: {
    loginChallenge(event) {
      const res = event.detail;
      if (res.token.toString().length > 10) {
        this.token = res.token;
      } else {
        this.token = "";
      }
    },
    eyeopen() {
      this.iseyeopen = !this.iseyeopen;
    },
    eyeopen2() {
      this.iseyeopen2 = !this.iseyeopen2;
    },
    passwordCheck(str) {
      let regExp = /(?=.*?[a-z])(?=.*?[0-9])(^.{6,}$)/; // 길이가 6자이상이고, 영어 소문자 포함여부 판단
      if (regExp.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    async pwok() {
      const pw = this.pw;
      const pwcheck = this.pwcheck;

      if (pw != pwcheck) {
        const event = new CustomEvent("error", {
          detail: {
            errortext:
              this.langlist[this.$store.state.lang][
                "비밀번호가 일치하지 않습니다."
              ],
          },
        });
        window.dispatchEvent(event);
        return;
      } else {
        if (this.passwordCheck(pw) && pw.length > 5) {
          // 비밀번호 변경
          // 재로그인으로 연결
          const newPassword = pw;
          const newPasswordCheck = pwcheck;

          const res = await userpasswordchange(this.token, {
            newPassword,
            newPasswordCheck,
          });

          if (!res.data.data) {
            this.pw = "";
            this.pwcheck = "";
            this.iseyeopen = false;
            this.iseyeopen2 = false;

            const event = new CustomEvent("error", {
              detail: {
                errortext:
                  this.langlist[this.$store.state.lang][
                    "새로운 비밀번호로 다시 로그인해주세요!"
                  ],
                flag: "init",
              },
            });

            window.dispatchEvent(event);
          } else {
            const event = new CustomEvent("error", {
              detail: {
                errortext:
                  this.langlist[this.$store.state.lang][
                    "비밀번호 설정 중 오류가 발생하였습니다"
                  ],
              },
            });

            window.dispatchEvent(event);
          }
        } else {
          const event = new CustomEvent("error", {
            detail: {
              errortext:
                this.langlist[this.$store.state.lang][
                  "비밀번호는 대소문자를 포함해 6자이상이여야 합니다."
                ],
            },
          });

          window.dispatchEvent(event);
          return;
        }
      }
    },
  },
};
</script>

<style scoped>
.logindiv {
  position: absolute;
  background-image: url("../assets/Login_Popup.png");
  width: 1100px;
  height: 600px;
  background-size: cover;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

input {
  background-color: #f4fbff;
  width: 80%;
  font-size: 120%;
  border-radius: 30px;
  border-bottom: 9px solid #607ccd;
  outline: none;
  height: 14%;
  padding-left: 80px;
  font-family: notosans;
}

.inputcontainer {
  background-image: url("../assets/Login_Input.png");
  width: 80%;
  background-size: 100%;
}

.pwinput1 {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../assets/pwicon.png");
  background-size: 5%;
  background-position-x: 20px;
  background-position-y: 15px;
}

.pwinput2 {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../assets/pwicon.png");
  background-size: 5%;
  background-position-x: 20px;
  background-position-y: 15px;
}

.pwokbtn {
  background-image: url("../assets/Btn_OK.png");
  background-size: 100%;
  width: 250px;
  height: 95px;
  cursor: pointer;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pwokbtn:hover {
  filter: brightness(0.8);
}

.whale {
  background-image: url("../assets/Login_Btn_Whale.png");
  width: 280px;
  height: 55px;
  background-size: 100%;
  position: absolute;
  bottom: 19%;
  left: 37%;
  cursor: pointer;
}

.whale:hover {
  filter: brightness(0.8);
}

input::-webkit-input-placeholder {
  color: #a4b5e5;
}
</style>