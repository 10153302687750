<template>
  <div
    style="
      zoom: 0.6;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      font-family: cookiebold;
      z-index: 100;
    "
  >
    <div class="modaltitle">
      <div style="margin-left: 350px; margin-top: 25px">
        <p
          style="
            color: rgb(13, 13, 126);
            font-size: 42px;
            font-weight: bold;
            position: absolute;
            margin-top: 5px;
          "
        >
          {{ langlist[$store.state.lang]["안내"] }}
        </p>
        <p
          style="
            color: white;
            font-size: 42px;
            font-weight: bold;
            position: absolute;
          "
        >
          {{ langlist[$store.state.lang]["안내"] }}
        </p>
      </div>
    </div>
    <div class="modalcontent">
      <div
        style="
          font-size: 40px;
          position: absolute;
          top: 35%;
          text-align: center;
          width: 100%;
        "
      >
        <p>{{ errortext }}</p>
      </div>
      <div style="position: absolute; margin-top: 470px; width: 100%">
        <div style="margin-left: 225px">
          <div @click="cancel()" class="pwokbtn"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ko, vt } from "@/_translate/translate";

export default {
  data() {
    return {
      langlist: {
        ko,
        vt,
      },
    };
  },
  props: {
    errortext: {
      type: String,
    },
    flag: {
      type: String,
    },
  },
  methods: {
    cancel() {
      if (this.flag == "init") {
        const event = new CustomEvent("error", {
          detail: { errortext: "", flag: "initconfirm" },
        });
        window.dispatchEvent(event);
        return;
      }

      const event = new CustomEvent("error", {
        detail: { errortext: "" },
      });
      window.dispatchEvent(event);
      return;
    },
  },
};
</script>

<style>
.pwokbtn {
  background-image: url("../assets/Btn_OK.png");
  background-size: 100%;
  width: 250px;
  height: 95px;
  cursor: pointer;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pwokbtn:hover {
  filter: brightness(0.8);
}

.modaltitle {
  background-image: url("../assets/modaltitlepanel.png");
  background-size: 100%;
  width: 800px;
  height: 160px;
  position: absolute;
  margin-top: -50px;
  margin-left: 95px;
}

.modalcontent {
  background-image: url("../assets/modalPopup.png");
  background-size: 100%;
  width: 900px;
  height: 570px;
  min-width: 1000px;
}
</style>