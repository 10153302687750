import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function getSaveCookie(cName) {
  cName = cName + '='
  const cookieData = document.cookie
  let start = cookieData.indexOf(cName)
  let cValue = ''
  if (start !== -1) {
    start += cName.length
    let end = cookieData.indexOf(';', start)
    if (end === -1) end = cookieData.length
    cValue = cookieData.substring(start, end)
  }
  return unescape(cValue)
}

function getLanguage() {
  const lang = navigator.language || navigator.userLanguage
  console.log('lang', lang)
  if (lang.includes('vi')) {
    return 'vt'
  } else {
    return 'ko'
  }
}

export default new Vuex.Store({
  state: {
    token: '',
    isLogin: '',
    firstAccess: getSaveCookie('first'),
    whaleloginmodal: false,
    lang: getLanguage()
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {}
})
